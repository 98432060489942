import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import GalleryMatch from "../components/gallery-match/gallery-match"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

export default function Sponsorship({data}) {

    //Sponsorship Page Data
    const sponsorPage = data?.wpPage?.sponsorship

    //Inner Hero Data
    const innerHero = sponsorPage?.sponsorshipInnerHero

    //Sponsor List Data
    const sponsorArr = sponsorPage?.sponsorsPicker

    //Match Gallery Data
    const matchData = sponsorPage?.sponsorshipMatchGallery



    useEffect(() => {
      AOS.init({
        duration: 2000
      });
      AOS.refresh();
    }, []);


    return (
        <>
        <DefaultLayout data={data}>
        <InnerPageHero innerData={innerHero} />
        <div className="rs-club" style={{paddingTop: "100px", paddingBottom: "0px"}}>
          <div className="container">
            <h3 className="title-bg">{data.wpPage.title}</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-8">
                <div className="rs-club-text">
                  <div dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <section className="sponsor-text-intro">
            <div className="rs-club">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col-md-8">
                    <h2 className="title-bg ">Please find below our 2022 - 2023 Sponsors</h2>
                    <p>We are incredibly grateful for the ongoing support of our fantastic sponsors, please find below our partners that are supporting us through the 2022-2023 season ahead.</p>
                    <p>If you are interested in sponsoring Saigon Raiders then please contact us at <a href="mailto:secretary@saigonraiders.com" target="_blank">secretary@saigonraiders.com</a> or via Facebook.</p>
                    <a class="primary-btn" href="mailto:secretary@saigonraiders.com" target="_blank">Get in touch</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

        {sponsorArr?.map((sponsor, index) => {


          if (sponsorArr.indexOf(sponsor) % 2 === 0) {
            return (
              <div className="rs-club" style={{paddingBottom: "100px"}} key={index}>
                <div className="container">
                  <h3 className="title-bg">{sponsor?.title}</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="rs-club-text">
                        <div 
                        dangerouslySetInnerHTML={{__html: sponsor?.hero?.sponsorShortSummary}}
                        data-aos="fade-up"
                        className=" mb-5"
                        data-aos-offset="100"
                        data-aos-delay="5"></div>
                        <div className="read-more" style={{marginBottom: "30px"}}>
                          <Link className="primary-btn" to={`/sponsorship/${sponsor?.slug}`}>Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="rs-club-image">
                        <GatsbyImage image={getImage(sponsor?.featuredImage?.node?.localFile)} alt={sponsor?.title} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          return (

            <div className="rs-club" style={{paddingBottom: "100px"}} key={index}>
                <div className="container">
                  <h3 className="title-bg">{sponsor?.title}</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="rs-club-image">
                        <GatsbyImage image={getImage(sponsor?.featuredImage?.node?.localFile)} alt={sponsor?.title} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="rs-club-text">
                        <div 
                        dangerouslySetInnerHTML={{__html: sponsor?.hero?.sponsorShortSummary}}
                        data-aos="fade-up"
                        className=" mb-5"
                        data-aos-offset="100"
                        data-aos-delay="5"></div>
                        <div className="read-more">
                        <Link className="primary-btn" to={`/sponsorship/${sponsor?.slug}`}>Read More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          );
        })}
        <GalleryMatch matchData={matchData} galleryTitle="Sponsor Gallery" />
        </DefaultLayout>
        </>
    )
}

export const query = graphql`
query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
        id
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
        sponsorship {
            sponsorshipInnerHero {
              leftButton {
                title
                url
              }
              rightButton {
                title
                url
              }
              title
              backgroundImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, height: 380)
                  }
                }
              }
            }
            sponsorsPicker {
              ... on WpSponsor {
                date
                slug
                uri
                title
                hero {
                  heroLink {
                    title
                    url
                  }
                  sponsorShortSummary
                }
                excerpt
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 540, height: 290)
                      }
                    }
                  }
                }
              }
            }
            sponsorshipMatchGallery {
                componentTitle
                matchImages {
                  instagramLink {
                    url
                  }
                  matchImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 440, height: 320)
                      }
                      publicURL
                    }
                  }
                  matchTitle
                }
            }
        }
    }
}
`